<template>
  <v-app id="inspire" light>
    <!-- app bar / header -->
    <Header />
    
    <!--  -->
    <Sidebar :navItems="$data.navigation.site" />
  
    <!--  -->
    <v-main>
      <router-view></router-view>
    </v-main>
    
    <!--  -->
    <Footer />
  
  </v-app>
</template>

<script>

import {
  Header,
  Sidebar,
  Footer
} from './layout';

export default {
  name: 'App',
  components: { 
    Header,
    Sidebar,
    Footer
  }
};
</script>
