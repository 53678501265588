import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sidebarOpen: null
  },
  mutations: {
    sidebarOpen(state, open){
      state.sidebarOpen = open;
    }
  },
  actions: {
    toggleSidebar({ state, commit }) {
      commit('sidebarOpen', !state.sidebarOpen);
    }
  },
  getters: {
    sidebarOpen(state){
      return state.sidebarOpen;
    }
  },
  modules: {

  }
})
