import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import ArticleRoutes from '@/content/articles/article-routes.js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/article',
    name: 'Article',
    // route level code-splitting
    // this generates a separate chunk (Article.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
    children: ArticleRoutes
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

export default router
