<template>
    <v-app-bar
      :color="this.$data.theme.navColor"
      class="pr-4"
      app
    >

      <v-app-bar-nav-icon
        v-if="$data.navigation.sidebarEnabled"
        class="white--text" 
        @click="$store.dispatch('toggleSidebar')" 
      />
      
      <v-toolbar-title class="app-title">[{{$data.siteName}}]</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- user profile icon -->
      <v-btn to="/" class="white--text" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn> 
    </v-app-bar>
</template>

<style lang="scss" scoped>
.app-title {
  color: #EEE;
}
</style>

<script>

export default {
  name: 'Header',
  components: {},
  data: () => ({
    retain: false
  }),
};

</script>
