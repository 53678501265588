<template>
  <v-btn 
    v-clipboard:copy="copyValue"
    v-clipboard:success="onCopy"
    v-clipboard:error="onError"
    icon
  >
    <v-icon>{{icon}}</v-icon>
  </v-btn>
</template>

<script>
// currently used for links
import { articleLink } from '@/helpers/links';

export default {
  name: 'Clipboard',
  props: {
    icon: {
      type: String,
      default: 'mdi-share-variant'
    },
    link: {
      type: String,
      required: true
    }
  },
  computed: {
    copyValue: function() {
      let link = this.link;
      if(link.indexOf('http://') === -1 && link.indexOf('https://') == -1){
        link = articleLink(this.link)
      }
      return link;
    }
  },
  methods: {
    onCopy: function (e) {
      // 
    },
    onError: function (e) {
      console.error(e);
    }
  },
  data: () => ({}),
}
</script>
