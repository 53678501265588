<template>
  <v-btn
    :to="path"
    text
    block
    v-show="display"
  >
    {{displayName}}
  </v-btn>
</template>

<script>

export default {
  name: 'SidebarButton',
  components: {},
  props:{
    displayName: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    display: {
      type: Boolean
    }
  },
  data: () => ({}),
};

</script>
