/*

*/

export default {
  sidebarEnabled: true,
  site: [
    { 
      type: 'view',
      path: '/',
      displayName: 'Home',
      display: true
    },
    { 
      type: 'view',
      path: 'articles',
      displayName: 'Articles',
      display: false
    },
    { 
      type: 'view',
      path: '/about',
      displayName: 'About',
      display: true
    }
  ],

};
