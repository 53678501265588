// import default picture

export default {
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  },
  createdAt: {
    type: String,
    required: false
  },
  updatedAt: {
    type: String,
    required: false
  },
  publishOn: {
    type: String,
    required: false
  },
  imgSrc: {
    type: String,
    required: false
  },
  // imgUrl: {
  //   type: String,
  //   required: false
  // },
  raised: {
    type: Boolean,
    default: false,
    required: false
  },
  friendlyUrl: {
    type: Boolean,
    required: false,
    default: false
  },
  link: {
    type: String,
    required: true
  }
}