<template>
  <v-col
    xl="3"
    lg="6"
    md="6"
    sm="6"
  >
    <v-card tile hover>
      <router-link class="text-decoration-none" :to="article.link">
        <v-img
          :src="thumbnailImg"
          class="align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          height="200px"
        >
          <v-card-title class="white--text" v-text="article.title"></v-card-title>
          <v-card-subtitle class="white--text" v-text="article.description" />
        </v-img>
      </router-link>
      <v-card-actions>
        {{fullDate}}
        <v-spacer></v-spacer>
        <Clipboard :link="article.link" />
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>

  import articleSchema from '@/schemas/article';
  import Clipboard from '@/components/Clipboard';

  export default {
    name: 'ArticleThumbnailLarge',
    components: {
      Clipboard
    },
    props: {
      article: articleSchema
    },
    computed: {
      fullDate(){
        return new Date(this.article.created).toDateString();
      },
      thumbnailCols() {
        return this.article.cols;
      },
      thumbnailImg() {
        if(this.article.thumbnailImgSrc) { 
          return require(`@/assets/${this.article.thumbnailImgSrc}`);
        } else if(this.article.thumbnailImgUrl) {
          return this.article.thumbnailImgUrl;
        }
        return '';
      }
    },
    data: () => ({}),
  }
</script>
