<template>
  <!-- 
    https://vuetifyjs.com/en/api/v-navigation-drawer/#component-pages
  -->
  <v-navigation-drawer
    :value="this.$store.getters.sidebarOpen"
    v-model="drawer"
    temporary
    app
  >
    <v-row 
      v-if="navItems.length > 0"
      class="mt-15"
      align="center"
    >
      <SidebarButton         
        v-for="item in navItems" 
        :key="item.displayName"
        v-bind="item"
        text
        block
      />
    </v-row>
  </v-navigation-drawer>
</template>

<script>

import SidebarButton from './SidebarButton';

export default {
  name: 'Sidebar',
  components: {
    SidebarButton
  },
  watch: {
    '$store.getters.sidebarOpen': function(sidebarOpen) {
      this.$set(this, 'drawer', sidebarOpen);
    },
    'drawer': function(drawer) {
      this.$store.commit('sidebarOpen', drawer);
    }
  },
  props:{
    navItems: Array
  },
  data: () => ({
    drawer: false
  }),
};

</script>
