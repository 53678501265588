<template>
  <div class="home">
    <v-img 
      :src="require(`@/assets/stock/stock-image-office.jpeg`)"
      :height="size.height"
    >
    <slot>
      <v-overlay
        absolute
        opacity=".3"
        z-index="0"
      />
      <v-row 
        justify="center" 
        :align="size.align" 
        class="text-center hero-banner"
      >
        <v-card color="rgba(256, 256, 256, 0.9)" :class="size.cardClasses" :width="size.width">
          <h1 class="text-h4 text-sm-h4 text-md-h4 text-lg-h4 text-xl-h4 font-weight-light mb-2">
            [software never dies]
          </h1>
          <p class="subheading text--secondary mb-0">
            Helping Developers & Technologists deliver solutions
          </p>
        </v-card>
      </v-row>
    </slot>
    </v-img>
    <v-container>
      <!-- Featured Articles -->
      <v-row 
        v-if="featuredArticles.length > 0"
        class="mt-sm-0 mt-md-2 mt-lg-5 mt-xl-5"
        dense
      >
        <v-col cols="12">
          <h3 class="text-h4 font-weight-light">Featured</h3>
        </v-col>
        <ArticleThumbnailLarge 
          v-for="(article, index) in featuredArticles"
          :key="`${index}-${article.title}`"
          :cols="article.cols"
          :article="article"
        />
      </v-row> 

      <v-row
        v-if="recentArticles.length > 0"
        class="mt-5"
        dense
      >
        <v-col cols="12">
          <h3 class="text-h4 font-weight-light mb-3">Recent</h3>
          <v-row dense>
            <ArticleThumbnailSmall 
              v-for="(article, index) in recentArticles"
              :key="`${index}-${article.title}`"
              :cols="article.cols"
              :article="article" 
            />
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.hero-banner {
  height: 100%;
}
.mobile-card {
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
}
.hero-card {
  max-width: 800px
}
</style>

<script>

// content
import { Articles } from '@/content';
import ArticleThumbnailLarge from '@/components/articles/ArticleThumbnailLarge';
import ArticleThumbnailSmall from '@/components/articles/ArticleThumbnailSmall';

// view/page layout components
// import ContentSlider from '@/components/layout/ContentSlider';

export default {
  name: 'Home',
  components: {
    // ContentSlider,
    ArticleThumbnailLarge,
    ArticleThumbnailSmall
  },
  computed: {
    size() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': 
          return {
            height: 300,
            align: 'end',
            width: '100%',
            cardClasses: 'py-5 px-5 mobile-card',
          }
        case 'sm': 
          return {
            height: 300,
            align: 'end',
            width: '100%',
            cardClasses: 'py-5 px-5 mobile-card',
          }
        case 'md': 
          return {
            height: 400,
            align: 'end',
            width: '100%',
            cardClasses: 'px-15 py-5 mobile-card',
          }
        case 'lg': 
          return {
            height: 500,
            align: 'center',
            width: '60%',
            cardClasses: 'px-15 py-10 hero-card',
          }
        case 'xl': 
          return {
            height: 600,
            align: 'center',
            width: '40%',
            cardClasses: 'px-15 py-10 hero-card',
          }
        default:           
          return {
            height: 300,
            align: 'center',
            width: '50%',
            cardClasses: 'px-15 py-5 hero-card',
          }
      }
    },
  },
  mounted() {

    const featuredArticles = Articles.filterbyType('featured');
    const recentArticles = Articles.filterbyType('general');

    featuredArticles
      .then((articles) => { 
        this.featuredArticles = articles;
      })
      .catch((err) => { console.error(err) });
    
    recentArticles
      .then((articles) => {
        this.recentArticles = articles;
      })
      .catch((err) => { console.error(err) });
    
  },
  data: () => ({
    featuredArticles: [],
    recentArticles: [],
  })
}
</script>
