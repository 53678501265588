import articleMap from './articles/article-map';

const FILTER_BY = {
  'featured': ''
};

function filterbyType(
  displayType = "featured" 
) {
  return new Promise((resolve) => {
    const now = Date.now();
    const filteredArchive = articleMap.filter((article) => {
      return (article.displayType == displayType && article.publishOn <= now);
    });

    resolve(filteredArchive);
  });
}

const Articles = {
  filterbyType
}

export {
  Articles
};