<template>
  <v-footer class="mt-10" padless>
    <v-col
      class="text-center"
      cols="12"
    >
      {{ new Date().getFullYear() }} — <strong>Software Never Dies</strong>
    </v-col>
  </v-footer>
</template>

<script>

export default {
  name: 'Footer',
  components: {},
  data: () => ({}),
};

</script>
