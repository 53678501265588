import images from './images';
import themes from './themes';
import navigation from './navigation';

const activeTheme = 'dark';

export default {
  siteName: 'SND',
  images ,
  theme: themes[activeTheme],
  navigation
 };